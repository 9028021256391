<template>
    <div class="result table__row table__row--body">
        <student :student="isResult.user"></student>
        <status :info="isResult.info"></status>
        <module v-for="module in isResult.modules" :key="`module_${module.id}`" :module="module"></module>
        <button
            class="result__btn"
            @click="deleteResult()"
        >
            <i class="fas fa-close dashboard__fa-icon result__icon"></i>
        </button>
    </div>
</template>

<script>
import session from '@/api/session';
import Student from '@/components/course/results/Student';
import Status from '@/components/course/results/Status';
import Module from '@/components/course/results/Module';

export default {
    name: 'Result',
    components: {
        Module,
        Status,
        Student
    },
    props: {
        isResult: {
            type: Object,
            required: true
        },
        isIdCourse: {
            type: String
        }
    },
    methods: {
        async deleteResult() {
            const isBlocked = this.isBlockedPortal();

            if (!isBlocked) {
                try {
                    await session.delete(`/api/v1/course/${this.isIdCourse}/end/`,
                        {
                            data: {
                                user_id: this.isResult.user.id
                            }
                        }
                    );

                    this.$swal({
                        title: 'Отлично',
                        text: 'Результаты сотрудника удалены',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1400
                    });

                    this.$emit('delete', this.isResult.user.id);
                } catch (err) {
                    this.$swal({
                        title: 'Ошибка!',
                        text: 'При обработке запроса произошла ошибка',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1400
                    });
                }
            }
        }
    }
};
</script>

<style scoped lang="sass">
    .result
        &__btn
            border: none
            background: transparent
            cursor: pointer
            position: absolute
            top: 50%
            right: 0
            transform: translateY(-50%)

        &__icon
            font-size: 1.25rem
            color: #e0e3f0

            &:hover
                color: #FBC04F
</style>
