<template>
    <router-link
        :to="`/auth/profile/${student.id}`"
        class="table__cell table__cell--name"
    >
        <div class="cell__inner">
            <div class="cell__inner-avatar">
                <img
                    :src="student.image_zoom_out || defaultAvatar"
                    @error="errorAvatar"
                />
            </div>

            <div class="cell__inner-text">
                <span class="cell__title">
                    {{ student.full_name }}
                </span>
                <span class="cell__position">{{ student.position }}</span>
            </div>
        </div>
    </router-link>
</template>

<script>
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';
    export default {
        name: "Student",
        data() {
            return {
                defaultAvatar
            }
        },
        props: {
            student: {
                type: Object,
                required: true
            }
        },
        methods: {
            errorAvatar(event) {
                event.target.setAttribute('src', this.defaultAvatar);
            }
        }
    }
</script>

<style scoped></style>
