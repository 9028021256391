<template>
    <div class="table__cell table__cell--status">
        <div class="cell__inner">
            <div class="cell__inner-status">
                {{ info.status }}
            </div>
            <div  class="cell__inner-message" v-if="info.review" v-tooltip="{ content: info.review, placement: 'top', offset: 10}">
                <i >
                    <icon height="16" width="18">
                        <path
                            d="M15.72 0H2.28C1.02 0 0 1.14388 0 2.51653V10.1805C0 11.5532 1.02 12.6971 2.28 12.6971H5.58L8.16 15.6139C8.64 16.1287 9.36 16.1287 9.84 15.6139L12.42 12.6971H15.72C16.98 12.6971 18 11.5532 18 10.1805V2.51653C18 1.14388 16.98 0 15.72 0Z"
                            fill="#FBC04F"
                        />
                    </icon>
                </i>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/icon/Icon'
export default {
    name: "Status",
    components: {
        Icon
    },
    props: {
        info: {
            type: Object,
            required: true
        },
    }
}
</script>

<style scoped>

</style>
