<template>
    <div class="table__cell table__cell--module">
        <div class="mobile-header">{{ module.number + " модуль" }}</div>
        <div v-if="module.lessons && module.lessons.length > 0" class="module__results module__results--lessons">
            <span v-for="lesson in module.lessons" :key="`lesson_${lesson.id}`"
                  :class="lesson.studied ? 'lesson-passed' : 'lesson-set'">
                {{ lesson.number }}
            </span>
        </div>
        <div v-if="module.test.score !== undefined" class="module__results module__results--tests">
            <span :class="module.test.score < module.test.total ? 'test-failed' : 'test-passed'">
                {{ module.test.score + "/" + module.test.total }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Module",
    props: {
        module: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
